import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { defaultLangKey } from '../data/languages';

type Props = {
  location: Location;
};

const NotFoundPage = ({ location }: Props) => (
  <Layout lang={defaultLangKey} location={location} pathnameWithoutLang={'404'}>
    <SEO title="404 Not found" description="" lang={defaultLangKey} meta={[]} />
    <section className="not-found">
      <div className="container">
        <div className="inner">
          <div className="not-found__desc">
            <p>Sorry, but the page you requested is not found.</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
